.gameError {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  width: 430px;
  max-width: 100%;
  min-height: 150px;
  padding: 60px 20px;
  border: 2px solid #FFA800;
  background-color: rgba(0,0,0,0.75);
  border-radius: 16px;
}

.title {
  text-align: center;
  font-size: 20px;
  margin: 0 0 32px;
  color: white;
  font-weight: 700;
}

.center {
  margin: 0 auto;
  text-align: center;
}

.flex {
  display: flex;
  justify-content: space-evenly;
}

.btn {
  width: 64px;
  height: 48px;
}